import { EvoModel } from '../evo-model';

export class UpdateOrganization extends EvoModel {

	public name: string;
	public logoUrl: string;

	public environments: any[];
	public sizeRange: string;
	public department: string;

	public products: any[];
}
