// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  COMPANY_NAME: 'evollo',
  APP_NAME: 'ADMIN',
  production: false,
  apiUrl: 'https://vivo-account.api.evollo.cloud',
  qualityApiUrl: 'https://vivo-quality.api.evollo.cloud',
  apps: {
    id: 'https://vivo.id.evollo.com.br/',
    quality: 'https://vivo.quality.evollo.com.br'
  },
	features: {
		enabledChat: false,
		enableOrgCreation: false,
		enableSupport: false,
		enableFaq: false,
    enableMicrosoftLogin: false
	}
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
