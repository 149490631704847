import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { PageResponse } from '@app/model';

const API_URL = environment.apiUrl;
const APP_NAME = environment.APP_NAME;

export interface BaseService {
	entity_url: string;
	app_name: string
}

export class BaseServiceClass<T> implements BaseService {

	public entity_url: string;
	public app_name: string;

	constructor(protected httpService: HttpClient, entitySuffix: string) {
		this.entity_url = API_URL + entitySuffix;
		this.app_name = APP_NAME;
	}

	public findAll(): Observable<T[]> {
		return this.httpService.get<T[]>(`${this.entity_url}`);
	}

	public find(id: number): Observable<T> {
		return this.httpService.get<T>(`${this.entity_url}/${id}`);
	}

	public post(obj: T): Observable<T> {
		return this.httpService.post<T>(`${this.entity_url}`, obj);
	}

	public put(obj: T, id: any): Observable<T> {
		return this.httpService.put<T>(`${this.entity_url}/${id}`, obj);
	}

	public delete(id: number): Observable<T> {
		return this.httpService.delete<T>(`${this.entity_url}/${id}`);
	}

	public filter(obj: any) {
		let newObj = {};
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				const element = obj[key];
				if (element !== null) {
					newObj = { ...newObj, [key]: element }
				}
			}
		}
		let options = { params: <any>newObj };
		return this.httpService.get<PageResponse<T>>(`${this.entity_url}`, options);
	}

}
