import { BaseServiceClass } from './base-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { Organization, UpdateOrganizationName, UpdateOrganizationLogoUrl } from '@app/model';
import { Products } from './product.service';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class OrganizationService extends BaseServiceClass<any> {

	public currentOrganization: Organization = new Organization();
	public forceOrganizationSelection: boolean = false;

	public currentTags: {product: string, name: string}[] = [];
	public defaultProducts = [ Products[Products.ADMIN], Products[Products.QUALITY] ];

	constructor(private http: HttpClient, private router: Router, private userService: UserService) {
		super(http, '/api/v1/organizations');
	}

	public find(id: any): Observable<any> {
		return this.http.get(`${this.entity_url}/${id}`);
	}

	public invite(obj: any): Observable<any> {
		let id = this.currentOrganization.id;

		return this.http.put(`${this.entity_url}/${id}/users`, obj);
	}

	public inviteTeam(obj: any): Observable<any> {
		let id = this.currentOrganization.id;
		return this.http.put(`${API_URL + '/api/v2/organizations'}/${id}/users`, obj);
	}

	public findAll(): Observable<any> {
		return this.http.get(`${this.entity_url}`);
	}

	public post(organization: any): Observable<any> {
		return this.http.post(`${this.entity_url}`, organization);
	}

	public put(organization: any, id: any): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}`, organization);
	}

	public updateOrganizationName(id: string, updateOrganizationName: UpdateOrganizationName): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}/name`, updateOrganizationName);
	}

	public updateOrganizationLogoUrl(id: string, updateOrganizationLogoUrl: UpdateOrganizationLogoUrl): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}/logoUrl`, updateOrganizationLogoUrl);
	}

	public updateTags(id: string, tags: {product: string, name: string}[]): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}/tags`, tags);
	}

	public updateLogo(formData: FormData): Observable<any> {
		return this.http.patch(`${this.entity_url}/logos`, formData);
	}

	public patchOrganizationStatus(id: any, status: string): Observable<any> {
		return this.http.patch(`${this.entity_url}/${id}/status/${status}`, {});
	}
}

export enum OrganizationsErrors {
	ALIAS_NOT_AVAILABLE = 'Alias da Organização não está disponível.',
	API_FIELDS_INVALID = 'Campos obrigatórios inválidos.'
}
