import { BaseServiceClass } from './base-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class PermissionService extends BaseServiceClass<any> {

	constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {
		super(http, '/api/v1/permissions');
	}
}

export enum AdminPermissions {
	ALL_PERMISSIONS = 'ALL_PERMISSIONS',
	TRAINING_ACCESS = 'TRAINING_ACCESS',
	EDIT_ORGANIZATION = 'EDIT_ORGANIZATION',
	EDIT_ROLES = 'EDIT_ROLES',
	CREATE_ROLES = 'CREATE_ROLES',
	VIEW_USERS = 'VIEW_USERS',
	EDIT_USERS_ROLES = 'EDIT_USERS_ROLES',
	VIEW_ROLES = 'VIEW_ROLES',
	REMOVE_USER_ORGANIZATION = 'REMOVE_USER_ORGANIZATION',
	INVITE_USER_ORGANIZATION = 'INVITE_USER_ORGANIZATION',
	MANAGE_USERS_INVITATION = 'MANAGE_USERS_INVITATION',
	INTERNAL = 'INTERNAL',
}

export enum ProductGroups {
	USERS = 'Usuários',
	ORGANIZATIONS = 'Organizações',
	ROLES = 'Perfis',
	SEGMENTS = 'Segmentos',
	OPERATIONS = 'Operações',
	CHECKLISTS = 'Check List',
	REPORTS = 'Relatórios',
	INTERACTIONS = 'Interações',
	EVALUATION = 'Monitoria',
	FLOWS = 'Fluxos',
	JUSTIFICATIONS = 'Justificativas',
	TRAINING = 'Training'
}
