import { Component } from '@angular/core';
import { UserService } from '@app/service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})

export class AppComponent {

	constructor(public userService: UserService, translate: TranslateService) {
		translate.addLangs(['pt-br']);
		translate.setDefaultLang('pt-br');
		translate.use('pt-br');
	}

}
