import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './base-service';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ExternalSpeechEnvironmentsService extends BaseServiceClass<any>  {

	constructor(public httpClient: HttpClient) {
		super(httpClient, '/external/speech/environments');
	}

	public findAll(): Observable<any> {
		return this.httpService.get(environment.qualityApiUrl + `/external/speech/environments`);
	}
}
