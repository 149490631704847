import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
	@Input()
	title: string = 'Confirmar';
	@Input()
	confirmText: string = 'Confirmar';
	@Input()
	text: string;

	@Input() showCancelButton: boolean = true;
	@Input() showConfirmButton: boolean = true;


	constructor(public modal: NgbActiveModal) {
	}
}
