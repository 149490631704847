<div class="modal-header">
	<h5 class="modal-title">Adicionar usuários</h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">

	<div class="search-div">
		<input class="search-input" [(ngModel)]="searchFilter" placeholder="Procurar" (keyup.enter)="filter(0);"/>
		<i class="fa fa-search" (click)="filter(0);"></i>
	</div>

	<div class="users-list">
		<div class="user-list-status" *ngIf="users.totalElements > 0">
			<span class="">{{ users.totalElements }} usuário<span *ngIf="users.totalElements != 1">s</span> encontrado<span *ngIf="users.totalElements != 1">s</span></span>
		</div>

		<div class="list">
			<!-- <div class="user-item" *ngFor="let user of users.items">
				<div class="form-check">
					<input class="form-check-input" type="checkbox" [value]="user" (change)="addRemoveUser(user)">
				</div>
				<evo-user-card [user]="user" [showEmail]="false"></evo-user-card>
			</div> -->
			<div class="user-item" *ngFor="let user of users.items; index as i">
				<input type="radio"
				       class="mr-3"
				       name="user_{{i}}"
				       id="radio_{{i}}"
				       [(ngModel)]="selectedUser"
				       [value]="user"/>
				<evo-user-card [user]="user"></evo-user-card>
		</div>

		<div class="user-list-result">
			<!-- <span class="text-primary" *ngIf="selectedUsers.length > 0">
				{{ selectedUsers.length }} usuário<span *ngIf="selectedUsers.length != 1">s</span> selecionado<span *ngIf="selectedUsers.length != 1">s</span>
			</span> -->
			<span class="text-muted" *ngIf="!selectedUser">Nenhum usuário selecionado</span>
		</div>
	</div>

</div>

<div class="modal-footer justify-content-end">
	<button type="button" class="btn btn-link" (click)="modal.dismiss()">Cancelar</button>
	<button type="button" class="btn btn-primary" [disabled]="!selectedUser || isLoading" (click)="emitAction()">
		<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
		Adicionar usuários
	</button>
</div>
