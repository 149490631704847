import { EvoModel } from './evo-model';
import { Organization } from './organization';

export class User extends EvoModel {

	public id: string;
	public name: string;
	public username: string;
	public email: string;
	public type: string;
	public status: any;
	public organizations: Organization[];
	public roles: any[];
	public createdAt: any;
	public updatedAt: any;

	public mfaRegistered: boolean;

	public isAdmin: boolean;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}

export enum UserStatusEnum {
	ACTIVE = <any>'Ativo',
	PENDING = <any>'Pendente',
	INACTIVE = <any>'Inativo',
	FORCE_PASSWORD_CHANGE = <any>'Troca de senha obrigatória'
}
