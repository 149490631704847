import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RoleService } from './role.service';

@Injectable({
	providedIn: 'root'
})
export class RoleDetailGuard implements CanActivate {

	public constructor(private roleService: RoleService, private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if(!this.roleService.selectedRole) {
			this.roleService.nextUrl = state.url;
			this.router.navigate(['/roles/' + next.parent?.params.id]);
			return false;
		}

		return true;
	}
}
