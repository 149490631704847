import { BaseServiceClass } from './base-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { OnboardingStep, OnboardingStepStatus } from '@app/model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class OnboardingStepService extends BaseServiceClass<any> {

	public currentOnboardingStep: OnboardingStep;

	public onboardingSteps: string[] = [
		'/organizations/onboarding/step-organization',
		'/organizations/onboarding/step-fields',
		'/organizations/onboarding/step-tags',
	];

	constructor(private http: HttpClient, private router: Router, private userService: UserService, @Inject(DOCUMENT) private document: Document) {
		super(http, '/api/v1/organizations/steps');
	}

	public put(onboardingStep: OnboardingStep): Observable<any> {
		return this.http.put(`${this.entity_url}`, onboardingStep);
	}

	public putNextOnboardingStep(currentStep?: number): any {
		if(!currentStep) { currentStep = this.currentOnboardingStep.step; }

		if(this.currentOnboardingStep && this.currentOnboardingStep?.step < this.onboardingSteps.length) {
			this.currentOnboardingStep.step = currentStep + 1;
		} else if(this.currentOnboardingStep.step == this.onboardingSteps.length) {
			this.currentOnboardingStep.status = OnboardingStepStatus.COMPLETED;
		}

		return this.put(this.currentOnboardingStep).subscribe(
			data => {
				if(this.currentOnboardingStep.status != OnboardingStepStatus.COMPLETED) {
					this.router.navigate([this.onboardingSteps[this.currentOnboardingStep.step - 1]]);
				} else {
					this.document.location.href = environment.apps['quality'];
				}
			});
	}
}
