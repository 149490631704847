import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelpDeskMessage } from '@app/model';
import { HelpDeskService, ToastService } from '@app/service';

@Component({
	selector: 'help-dialog',
	templateUrl: './help-dialog.component.html',
	styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {

	public helpDeskMesssage: HelpDeskMessage = new HelpDeskMessage();
	public isLoading: boolean;

	public messageForm: FormGroup;

	constructor(public modal: NgbActiveModal,
	            public helpDeskService: HelpDeskService,
				private formBuilder: FormBuilder,
	            private toastService: ToastService) {
	}

	public ngOnInit(): void {
		this.messageForm = this.formBuilder.group({
			subject: ['', [Validators.required, Validators.min(5)]],
			message: ['', [Validators.required, Validators.min(5)]],
		});
	}

	public postHelpMessage(): void {
		this.helpDeskMesssage.subject = this.messageForm.get('subject')?.value;
		this.helpDeskMesssage.message = this.messageForm.get('message')?.value;

		this.isLoading = true;

		this.helpDeskService.sendMessage(this.helpDeskMesssage).subscribe(
			data => {
				this.toastService.showSuccess('Mensagem enviada, entraremos em contato em breve.');
				this.modal.close();
				return;
			}, error => {
				this.toastService.showDanger('Ocorreu um erro ao tentar enviar sua mensagem.');
				console.error(error);
			}).add(() => this.isLoading = false);
	}


}
