import { Component, Input } from '@angular/core';

@Component({
    selector: 'avatar-initials',
    templateUrl: './avatar-initials.component.html',
    styleUrls: ['./avatar-initials.component.scss']
})
export class AvatarInitialsComponent {

    constructor() {
    }

    @Input() public photoUrl: string = '';
    @Input() public name: string = '';
    @Input() public defaultColor: boolean = false;
    @Input() large: boolean = false;
    @Input() small: boolean = false;
    @Input() public circleColor: string = '';
    @Input() public initialsColor: string = '';

	public showInitials = false;
	public initials: string = '';

    private colors = [
        '#1839B4',
    ];

    ngOnInit() {
		this.noPhotoUrlInit();
    }

    noPhotoUrlInit(): void {
        this.showInitials = true;
        this.createInititals();
        if (this.circleColor) return;

        if (this.defaultColor) {
            this.circleColor = this.colors[0];
        } else {
            const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
            this.circleColor = this.colors[randomIndex];
        }
    }

    private createInititals(): void {
        let initials = "";
        let upperCaseName = this.name?.toUpperCase().split('');
        initials = upperCaseName[0];

        for (let i = 0; i < upperCaseName.length; i++) {
            const element = upperCaseName[i];
            if (element === ' ') {
                initials += upperCaseName[i + 1]
                if (initials.length == 2) {
                    break;
                }
            }
        }

        this.initials = initials;
    }
}
