import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminPermissions, OrganizationService, PermissionService, ProductGroups, Products, UserService } from '@app/service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
	selector: 'evo-products-permissions',
	templateUrl: './evo-products-permissions.component.html',
	styleUrls: ['./evo-products-permissions.component.scss']
})
export class EvoProductsPermissionsComponent implements OnInit {

	@Input() public productsPermissions: any[] = [];
	@Input() public selectedPermissions: any[] = [];

	@Output() public permissionsChanged = new EventEmitter<any>();

	public tempSelectedPermissions: any[] = [];

	public canEdit: boolean = false;

	constructor(public userService: UserService,
	            public organizationService: OrganizationService,
				public permissionService: PermissionService,
				public ngxPermissionService: NgxPermissionsService,
	            private router: Router,
	            private translate: TranslateService) {
	}

	ngOnInit(): void {
		this.getPermissions();
		this.userHasEditPermission();
	}

	public get products(): any { return Products; }
	public get productGroups(): any { return ProductGroups; }

	public getPermissions(): void {
		this.permissionService.findAll().subscribe(
			data => {

				let permissionsByProduct = this.groupBy(data, 'product');
				let permissionsByProductArr: any[] = [];

				Object.keys(permissionsByProduct).forEach( (key: string, index) => {
					permissionsByProductArr = [ ...permissionsByProductArr, permissionsByProduct[key as any]];
				}, permissionsByProduct);

				let permissionByProductByGroup: any[] = [];

				permissionsByProductArr.forEach( (productPermissions) => {
					productPermissions = this.groupBy(productPermissions, 'group');
					permissionByProductByGroup.push(productPermissions);
				});

				permissionByProductByGroup.forEach( (item) => {
					let product: any[] = [];
					Object.keys(item).forEach( (key: string, index) => {
						product = [ ...product, item[key as any]];
					}, item);

					this.productsPermissions.push(product);
				});

				this.productsPermissions.forEach((value, index) => {
					value.forEach((value2: any, index2: number) => {

						this.tempSelectedPermissions[index + '_' + index2] = [];

						value2.forEach((permission: any, permissionIndex: number) => {
							this.translate.get(['permissions.' + permission.name])
							.subscribe(translations => {
								permission.description = translations['permissions.' + permission.name];
							});

							this.selectedPermissions.forEach((item) => {
								if(item.id == permission.id) {
									this.tempSelectedPermissions[index + '_' + index2] = [...this.tempSelectedPermissions[index + '_' + index2], permission];
								}
							});
						});
					});
				});

				this.selectedPermissions = this.tempSelectedPermissions;
			}, error => {
				console.error(error);
			});
	}

	groupBy(objectArray: any[], property: string, onlyId: boolean = false) {
		return objectArray.reduce(function (acc, obj) {
			var key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}

			if(!onlyId) {
				acc[key].push(obj);
			} else {
				acc[key].push(obj.id);
			}

			return acc;
		}, []);
	}

	public onPermissionsChanged(value: any[]) {
		setTimeout(() => {
			let flat = this.flatPermissions();

			this.permissionsChanged.emit(flat);
		}, 0);
	}

	private flatPermissions(): any[] {
		let finalArray: any[] = [];

		Object.keys(this.selectedPermissions).forEach( (key: string, index) => {
			finalArray = [ ...finalArray, this.selectedPermissions[key as any]];
		}, this.selectedPermissions);

		let permissions: any[] = [];

		finalArray.forEach( (item: any[]) => {
			item.forEach((permission: any) => {
				permissions = [ ...permissions, permission.id ]
			})
		});

		return permissions;
	}

	public getItemLabelByValue(items: any, value: any) {
		const item = items.find((item: { id: any; }) => item.id == value.id);
		return item ? item.description : '';
	}

	trackByIndex(index: number, obj: any): any {
		return index;
	}

	public userHasEditPermission(): void {
		this.ngxPermissionService.hasPermission([AdminPermissions.ALL_PERMISSIONS, AdminPermissions.EDIT_ROLES]).then(
			data => {
				this.canEdit = data;
			});
	}

}
