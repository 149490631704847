<div class="evo-switch">
	<div class="switch-header">
		<span>Alterar para</span>

		<div>
			<button (click)="onSwitchOrganizations()" *ngIf="showOrganizationButton || isInternal">
				Organizações
			</button>
		</div>
	</div>

	<div class="switch-products">
		<div class="switch-product" *ngFor="let product of products">
			<div class="switch-button" *ngIf="product.alias !== 'ADMIN'">
				<a class="d-flex align-items-center" [href]="getQualityUrl(product)">
					<div class="evo-product" [ngClass]="product.alias"></div>
					<span>{{product.name}}</span>
				</a>

				<i class="fas ml-auto" [ngClass]="{'fa-chevron-up': product.isOpen, 'fa-chevron-down': !product.isOpen}" (click)="product.isOpen = !product.isOpen"></i>
			</div>

			<div class="product-organizations" [ngClass]="{'is-open': product.isOpen}" *ngIf="product.organizations">
				<a class="switch-button" *ngFor="let organization of product.organizations" [href]="product.accessUrl + '/organizations?organization=' + organization.alias">
					<div class="logo" *ngIf="organization.logoUrl" [ngStyle]="{'background-image' : 'url('+ organization.logoUrl +')'}"></div>
					<div class="logo no-logo" *ngIf="!organization.logoUrl">
						{{ organization.name[0] }}
					</div>
					<span>{{ organization.name }}</span>

					<!-- <i class="fas fa-cog"></i> -->
				</a>
			</div>
		</div>

		<div class="switch-product" *ngIf="showTraining">
			<div class="switch-button">
				<a class="d-flex align-items-center" style="width: 100%" (click)="goToTraining()">
					<div class="evo-product TRAINING"></div>
					<span>Training</span>
					<span *ngIf="loading" class="ml-auto text-primary spinner-border spinner-border-sm mr-1"></span>
				</a>
			</div>
		</div>
	</div>

	<div class="switch-footer">
		<div class="switch-button" *ngIf="showAdmin">
			<i class="fas fa-cog"></i>
			<span class="">Administração da organização</span>
		</div>
	</div>
</div>
