<!-- <div class="circle" style="height: {{circleSize}}; width: {{circleSize}}">
    <span class="initials" style="font-size:{{fontSize}};  top: {{topSize}}">{{initials}}</span>
</div> -->

<div [ngClass]="{'small' : small, 'large': large}" class="circle" [ngStyle]="{'background-color':  circleColor }">
    <img *ngIf="!showInitials" src="{{photoUrl}}">

    <div *ngIf="showInitials" class="initials initials-default-color">
        {{ initials }}
    </div>
</div>
