import { EvoModel } from './evo-model';
import { Organization } from './organization';
import { Permission } from './permission';

export class Role extends EvoModel {

	public id: string;
	public code: number;
	public name: string;
	public description: string;
	public organization: Organization;
	public permissions: Permission[];
	public createdAt: any;
	public updatedAt: any;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}
