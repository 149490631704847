import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

class ToastModelOptions {
	className: string = '';
	delayTime: number = 5000;
	hideCloseButton?: boolean = false;
	allowUndoAction?: boolean = false;

	constructor(props: any) {
		if (props) {
			Object.assign(this, props);
		}
	}
}

class ToastModel extends ToastModelOptions {
	textOrTpl!: TemplateRef<any>;
	observer!: Subscriber<any>;

	constructor(props: any) {
		if (props) {
			super(props);
			Object.assign(this, props);
		}
	}
}

export enum SystemMessages {
	UNEXPECTED_ERROR = 'Ocorreu um erro inesperado. Por favor, tente novamente ou contate o administrador do seu sistema.',
	USER_LASTNAME_REQUIRED = 'USER_LASTNAME_REQUIRED'
}

@Injectable({providedIn: 'root'})
export class ToastService {
	toasts: ToastModel[] = [];

	// Callback method to remove Toast DOM element from view
	removeToast(toast: any) {
		this.toasts = this.toasts.filter(t => t !== toast);
	}

	cancelToastRequest(toast: any) {
		toast.observer.error();
		this.removeToast(toast);
	}

	// Push new Toasts to array with content and options
	showToast(textOrTpl: string | TemplateRef<any>, options: ToastModelOptions = {} as ToastModelOptions): Observable<any> {
		const toastItem = new ToastModel({textOrTpl, ...options});
		this.toasts.push(toastItem);

		if (toastItem.allowUndoAction) {
			return new Observable((observer) => {
				toastItem.observer = observer;
				setTimeout(() => observer.next(), toastItem.delayTime);
			});
		} else {
			return new Observable();
		}
	}

	showStandard(textOrTpl: string, options: ToastModelOptions = {} as ToastModelOptions): Observable<any> {
		return this.showToast(textOrTpl, options);
	}

	showSuccess(textOrTpl: string, options: ToastModelOptions = {} as ToastModelOptions): Observable<any> {
		return this.showToast(textOrTpl, {
			className: 'toast-success',
			...options
		});
	}

	showWarning(textOrTpl: string, options: ToastModelOptions = {} as ToastModelOptions): Observable<any> {
		return this.showToast(textOrTpl, {
			className: 'toast-warning',
			...options
		});
	}

	showDanger(textOrTpl: string, options: ToastModelOptions = {} as ToastModelOptions): Observable<any> {
		return this.showToast(textOrTpl, {
			className: 'toast-danger',
			...options
		});
	}

	showLight(textOrTpl: string, options: ToastModelOptions = {} as ToastModelOptions): Observable<any> {
		return this.showToast(textOrTpl, {
			className: 'toast-light',
			...options
		});
	}

	showDefaultDanger(): Observable<any> {
		return this.showDanger(SystemMessages.UNEXPECTED_ERROR);
	}
}
