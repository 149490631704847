import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
	selector: 'evo-inline-edit',
	templateUrl: './inline-edit-input.component.html',
	styleUrls: ['./inline-edit-input.component.scss']
})
export class InlineEditInputComponent {

	@Output() public changeDone: EventEmitter<any> = new EventEmitter<any>();
	@Input() public value: any;

	@Input() public placeholder: string;

	@Input() public permissions: string[] | string;
	@Input() public isCenter: boolean = false;
	@Input() public isTitle: boolean = true;

	public tempValue: string;
	public showInput: boolean = false;

	constructor(public ngxPermissionsService: NgxPermissionsService) {
	}

	public onShowInput(): void {
		this.ngxPermissionsService.hasPermission(this.permissions).then(
			data => {
				if(data) {
					this.showInput = true;
					this.tempValue = this.value;
				}
			});
	}

	public onChangeCancel(): void {
		this.showInput = false;
	}

	public onChangeDone(): void {
		this.changeDone.emit(this.tempValue);
		this.showInput = false;
	}
}
