import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastService } from '@app/service';

@Component({
	selector: 'app-toasts',
	templateUrl: 'toast.component.html'
})
export class ToastComponent {
	@HostBinding('attr.class') class = 'ngb-toasts';

	constructor(public toastService: ToastService) {
	}

	isTemplate(toast: any) {
		return toast.textOrTpl instanceof TemplateRef;
	}
}
