import { BaseServiceClass } from './base-service';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Role, UpdateOrganizationName } from '@app/model';
import { Observable } from 'rxjs';
import { UpdateOrganizationDescription } from 'app/model/request/update-organization-description';

@Injectable({
	providedIn: 'root'
})
export class RoleService extends BaseServiceClass<any> {

	public selectedRole: Role;

	public nextUrl: string | undefined;

	constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {
		super(http, '/api/v1/roles');
	}

	public find(id: any): Observable<any> {
		return this.http.get(`${this.entity_url}/${id}`);
	}

	public delete(id: any): Observable<any> {
		return this.http.delete(`${this.entity_url}/${id}`);
	}

	public updateRoleName(id: string, updateOrganizationName: UpdateOrganizationName): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}/name`, updateOrganizationName);
	}

	public updateRoleDescription(id: string, updateOrganizationDescription: UpdateOrganizationDescription): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}/description`, updateOrganizationDescription);
	}

// "/api/v1/roles/{id}/name":
}
