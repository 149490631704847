import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'evo-upload-file-input',
	templateUrl: './upload-file.component.html',
	styleUrls: ['./upload-file.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => UploadFileComponent),
		multi: true
	}]
})
export class UploadFileComponent implements ControlValueAccessor {
	@Input() rules: string[] = [];
	@Input() accept: string = '*';
	@Input() violatedRules: string[] = [];
	@Input() isMultiple: boolean = true;
	@Input() showFileInfo: boolean = false;
	@Input() buttonLabel: string = '<i class="fas fa-cloud-upload-alt mr-1"></i>Adicione arquivos';

	onChange: any = () => {
	};
	onTouch: any = () => {
	};

	files: File[] = [];

	set value(val: any) {
		if (val !== undefined && this.files !== val) {
			this.files = val;
			this.onChange(val);
			this.onTouch(val);
		}
	}

	isFirstWrite: boolean = true;

	writeValue(value: any) {
		this.value = this.isMultiple && this.isFirstWrite ? [...this.files, ...Array.from(value.target.files)] : Array.from(value.target.files);
		this.isFirstWrite = false;
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouch = fn;
	}

	constructor() {
	}

	removeFile(currFile: File) {
		// @ts-ignore
		this.writeValue(this.files.filter(_file => _file.name !== currFile.name));
	}

}
