import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminPermissions, AuthGuard } from '@app/service';
import { LoginComponent } from './views/login/login.component';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: '',
		redirectTo: '/users',
		pathMatch: 'full'
	},
	{
		path: 'organizations',
		loadChildren: () => import('./views/organizations/organizations.module').then(m => m.OrganizationsModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'users',
		loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: [AdminPermissions.VIEW_USERS, AdminPermissions.INVITE_USER_ORGANIZATION, AdminPermissions.MANAGE_USERS_INVITATION, AdminPermissions.ALL_PERMISSIONS],
				redirectTo: 'no-access'
			}
		}
	},
	{
		path: 'roles',
		loadChildren: () => import('./views/roles/roles.module').then(m => m.RolesModule),
		canActivate: [AuthGuard, NgxPermissionsGuard],
		data: {
			permissions: {
				only: [AdminPermissions.VIEW_ROLES, AdminPermissions.CREATE_ROLES, AdminPermissions.EDIT_ROLES, AdminPermissions.EDIT_USERS_ROLES, AdminPermissions.ALL_PERMISSIONS],
				redirectTo: 'no-access'
			}
		}
	},
	{
		path: 'no-access',
		loadChildren: () => import('./views/no-access/no-access.module').then(m => m.NoAccessModule),
		canActivate: [AuthGuard]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
