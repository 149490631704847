export interface PageResponse <T>{
    items: T[];
    size: number;
    page: number;
	totalElements: number;
	totalPages: number;
}
export interface Pageable{
    offset: number;
    pageSize: number;
    pageNumber: number;
    unpaged: boolean;
    paged: boolean;
    sort: Sortable;
}
export interface Sortable{
    sorted: boolean;
    unsorted: boolean;
}

export class EmptyPage<T> implements PageResponse<T> {
    items: T[];
    size: number;
	page: number;
	totalElements: number;
	totalPages: number;

    constructor() {
        this.items = [];
        this.page = 0;
        this.size = 5;
	    this.totalElements = 0;
	    this.totalPages = 0;
    }
}
