<div class="d-flex flex-column">
    <label class="btn">
        <span class="text-primary" [innerHTML]="buttonLabel"></span>
        <input (change)="writeValue($event)" type="file" [accept]="accept" hidden [multiple]="isMultiple"/>
    </label>
    <div id="rules" class="mb-1" *ngIf="violatedRules.length === 0">
        <p class="m-0 text-muted" *ngFor="let rule of rules">{{rule}}</p>
    </div>
    <div id="violatedRules" class="mb-1" *ngIf="violatedRules.length > 0">
        <p class="m-0 text-danger" *ngFor="let rule of violatedRules">{{rule}}</p>
    </div>
    <div id="files" *ngIf="showFileInfo">
        <div class="file w-100 d-flex justify-content-between align-items-center mb-1" *ngFor="let file of files">
            <span class="file-name">{{file.name}}</span>
            <button class="btn btn-sm pt-0 pb-0" type="button" (click)="removeFile(file)"><i class="fas fa-times"></i></button>
        </div>
    </div>
</div>
