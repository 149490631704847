<div class="inline-edit">
	<h6 *ngIf="!showInput && isTitle" class="text-center" [ngClass]="{'text-center': isCenter}" (click)="onShowInput()">{{ value }}</h6>
	<p *ngIf="!showInput && !isTitle && value" class="text-center" [ngClass]="{'text-center': isCenter}" (click)="onShowInput()">{{ value }}</p>

	<p *ngIf="!showInput && !isTitle && !value && placeholder" class="text-center text-muted" [ngClass]="{'text-center': isCenter}" (click)="onShowInput()">{{ placeholder }}</p>

	<div class="d-flex flex-column" style="width: 100%" *ngIf="showInput">
		<input class="form-control" [ngClass]="{'text-center': isCenter, 'input-title': isTitle}" name="name" [(ngModel)]="tempValue"/>

		<div class="inline-edit-action">
			<button class="btn btn-edit mr-2" (click)="onChangeDone()"><i class="fas fa-check"></i></button>
			<button class="btn btn-edit" (click)="onChangeCancel()"><i class="fas fa-times"></i></button>
		</div>
	</div>
</div>
