import { Component, Input } from '@angular/core';

@Component({
    selector: 'evo-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent {

	@Input() public user: any;
	@Input() public showEmail: boolean = true;

	public showInitials = true;
	public initials: string = 'EV';

    constructor() {
    }

    ngOnInit() {
		this.createInititals();
    }

    private createInititals(): void {
        let initials = "";
        let upperCaseName = (this.user.name ? this.user.name.toUpperCase().split('') : this.user.email );
        initials = upperCaseName[0];

        for (let i = 0; i < upperCaseName.length; i++) {
            const element = upperCaseName[i];
            if (element === ' ') {
                initials += upperCaseName[i + 1]
                if (initials.length == 2) {
                    break;
                }
            }
        }

        this.initials = initials;
    }
}
