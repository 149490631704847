import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[lowercase]',
    host: {
        '(input)': '$event',
    },
})
export class LowerCaseInputDirective {
    lastValue: string;

    constructor(public ref: ElementRef) { }

    @HostListener('input', ['$event']) onInput($event: any) {
        var start = $event.target.selectionStart;
        var end = $event.target.selectionEnd;
        $event.target.value = $event.target.value.toLowerCase();
        $event.target.setSelectionRange(start, end);
        $event.preventDefault();

        
    }
}