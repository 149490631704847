import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { UserCardComponent } from './user-card/user-card.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { SelectComponent } from './select/select.component';
import { ToastComponent } from './toast/toast.component';
import { HeaderComponent } from './header/header.component';
import { AvatarInitialsComponent } from './avatar-initials/avatar-initials.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UploadFileComponent } from './upload-file/upload-file.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DateFnsModule } from 'ngx-date-fns';

import { CheckIFDate } from './pipes/checkIFDate.pipe';
import { UserSearchComponent } from './user-search/user-search.component';
import { EvoProductsPermissionsComponent } from './evo-products-permissions/evo-products-permissions.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmActionDirective } from './directives/confirm-action/confirm-action.directive';
import { InlineEditInputComponent } from './inline-edit-input/inline-edit-input.component';
import { EvoSwitchComponent } from './evo-switch/evo-switch.component';

import { NgxPermissionsModule } from 'ngx-permissions';
import { CheckableCardComponent } from './checkable-card/checkable-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { LowerCaseInputDirective } from './directives/lowercase/lowercase.directive';
import { TrainingErrorDialogComponent } from './training-error-dialog/training-error-dialog.component';
import { OnlyPremiumDialogComponent } from './only-premium-dialog/only-premium-dialog.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		NgSelectModule,
		NgxDatatableModule,
		DateFnsModule.forRoot(),
		NgxPermissionsModule.forChild(),
		TranslateModule,
	],
	declarations: [
		SelectComponent,
		ToastComponent,
		HeaderComponent,
		SidebarComponent,
		AvatarInitialsComponent,
		UploadFileComponent,
		UserCardComponent,
		SearchInputComponent,
		UserSearchComponent,
		EvoProductsPermissionsComponent,
		ConfirmDialogComponent,
		HelpDialogComponent,
		ConfirmActionDirective,
		InlineEditInputComponent,
		EvoSwitchComponent,
		CheckableCardComponent,
		LowerCaseInputDirective,
		TrainingErrorDialogComponent,
		OnlyPremiumDialogComponent,

		CheckIFDate,
	],
	providers: [],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		NgSelectModule,
		NgxDatatableModule,
		NgxPermissionsModule,
		TranslateModule,

		SelectComponent,
		ToastComponent,
		HeaderComponent,
		SidebarComponent,
		AvatarInitialsComponent,
		UploadFileComponent,
		DateFnsModule,
		UserCardComponent,
		SearchInputComponent,
		UserSearchComponent,
		EvoProductsPermissionsComponent,
		ConfirmDialogComponent,
		ConfirmActionDirective,
		HelpDialogComponent,
		InlineEditInputComponent,
		EvoSwitchComponent,
		CheckableCardComponent,
		LowerCaseInputDirective,
		TrainingErrorDialogComponent,
		OnlyPremiumDialogComponent,

		CheckIFDate,
	]
})

export class SharedModule {
}
