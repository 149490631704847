import { EvoModel } from './evo-model';

export class Organization extends EvoModel {

	public id: string;
	public code: number;
	public name: string;
	public alias: string;

	public status: string;
	public createdAt: any;

	public sizeRange: string;
	public department: string;
	public logoUrl: any;

	public organizationTags: any[];
	public environments: any[];

	public admin: boolean;
	public products: any[];
	public profiles: any[];

	public steps: any;

	public plan: {
		id: string;
		name: string;
		type: string;
		status: string;
		training: boolean;
		automaticChecklist: boolean;
		availableDashboards: string[];
		interactionsPerMonth: number;
	}
}
export enum OrganizationStatusEnum {
	ACTIVE = <any>'Ativo',
	INACTIVE = <any>'Inativo',
}
