import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AdminPermissions, OrganizationService, SystemMessages, ToastService, TrainingService } from '@app/service';
import { DOCUMENT } from '@angular/common';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TrainingErrorDialogComponent } from '../training-error-dialog/training-error-dialog.component';
import { OnlyPremiumDialogComponent } from '../only-premium-dialog/only-premium-dialog.component';

@Component({
	selector: 'evo-switch',
	templateUrl: './evo-switch.component.html',
	styleUrls: ['./evo-switch.component.scss']
})
export class EvoSwitchComponent implements OnInit {

	@Input() isInternal: boolean = false;

	public showOrganizationButton: boolean = false;
	public products: SwitchProducts[] = [];

	@Output() switchOrganizations: EventEmitter<any> = new EventEmitter<any>();
	@Input() public showAdmin: boolean = true;

	public showTraining: boolean = false;
	public adminPermissions: AdminPermissions;

	public loading: boolean = false;

	@Input()
	public set switchProductsOrganizations(value: SwitchProductsOrganizations) {
		value.allProducts.forEach(product => {
			let switchProducts = new SwitchProducts();
			switchProducts.alias = product.alias;
			switchProducts.name = product.name;
			switchProducts.accessUrl = product.accessUrl;
			switchProducts.organizations = [];

			this.showOrganizationButton = value.allOrganizations.length > 1;

			value.allOrganizations.forEach((organization: any) => {
				organization.products.forEach((organizationProduct: { alias: string; }) => {
					if(organizationProduct.alias == product.alias) {
						switchProducts.organizations = [ ...switchProducts.organizations, organization ];
					}
				});
			});

			this.products.push(switchProducts);
		});
	}

	constructor(private router: Router,
	            public organizationService: OrganizationService,
	            public trainingService: TrainingService,
				public ngxPermissionService: NgxPermissionsService,
				private modalService: NgbModal,
				private toastService: ToastService,
	            @Inject(DOCUMENT) private document: Document) {
	}

	ngOnInit(): void {
		this.ngxPermissionService.hasPermission([AdminPermissions.ALL_PERMISSIONS, AdminPermissions.TRAINING_ACCESS]).then(
			data => {
				this.showTraining = data;
			});
	}

	public goToTraining(): void {
		if(this.loading) {
			return;
		}

		if(this.organizationService.currentOrganization.plan.training) {
			this.loading = true;
			this.trainingService.getLoginUrl().subscribe(
				data => {
					this.document.location.href = data.url;
				}, error => {
					if(error.status == 400 && error.error.error == SystemMessages.USER_LASTNAME_REQUIRED) {
						this.modalService.open(TrainingErrorDialogComponent, {});
					} else {
						this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
					}
				}).add( () => {
					this.loading = false;
				});
		} else {
			this.modalService.open(OnlyPremiumDialogComponent, {size: 'lg'});
		}
	}

	public onSwitchOrganizations(): void {
		this.switchOrganizations.emit();
	}

	public get idUrl(): string {
		return environment.apps['id'];
	}

	public getQualityUrl(product: any): string {
		let url = product.accessUrl;

		if(this.organizationService.currentOrganization.id) {
			url += '/organizations?organization=' + this.organizationService.currentOrganization.alias
		}

		return url;
	}
}

export class SwitchProducts {
	public alias: string;
	public name: string;
	public organizations: any[];
	public accessUrl: string;

	public isOpen: boolean = false;
}

export class SwitchProductsOrganizations {
	public allProducts: any[];
	public allOrganizations: any[];
}


