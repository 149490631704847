import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	public constructor(private userService: UserService,
	                   private router: Router,
	                   private ngxPermissionsService: NgxPermissionsService) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.userService.currentUser) {
			return true;
		}

		if(next.queryParams['organization']) {
			localStorage.setItem('switchOrganization', next.queryParams['organization']);
		}

		this.userService.returnUrl = state.url;

		/* if(state.url == '/') {
			this.userService.returnUrl = '/organizations';
		}*/

		this.router.navigate(['/login']);
		return false;
	}
}
