import { Component, OnInit } from '@angular/core';
import { AdminPermissions, OrganizationService, ProductService, UserService } from '@app/service';
import { Router } from '@angular/router';
import { Organization, Product } from '@app/model';
import { switchMap } from 'rxjs/operators';
import { SwitchProductsOrganizations } from '../evo-switch/evo-switch.component';
import { environment } from '../../../environments/environment';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	constructor(public userService: UserService,
	            public organizationService: OrganizationService,
				public productService: ProductService,
				public modalService: NgbModal,
	            private router: Router) {
	}

	public showSupport: boolean = environment.features.enableSupport;
	public showFaq: boolean = environment.features.enableFaq;

	public products: Product[] = [];
	public organizations: Organization[] = [];

	public switchProductsOrganizations: SwitchProductsOrganizations;

	public finalArray: any[] = []

	public showSwitch: boolean = false;

	public adminPermissionsArr: any[];

	public get idUrl(): string {
		return environment.apps['id'];
	}

	ngOnInit(): void {
		this.organizationService.findAll().pipe(
			switchMap((value) => {
				this.organizations = value.filter((organization: Organization) => organization.status == 'ACTIVE');
				return this.productService.findAll();
			})
		).subscribe(
			data => {
				this.switchProductsOrganizations = new SwitchProductsOrganizations();
				this.switchProductsOrganizations.allProducts = data;
				this.switchProductsOrganizations.allOrganizations = this.organizations;
			});

		this.adminPermissionsArr = Object.values(AdminPermissions);
	}

	public openHelpDialog(): void {
		const modalRef = this.modalService.open(HelpDialogComponent, {});

		modalRef.result.then( data => {
			if(data) {
			}
		})
	}

	public get adminPermissions(): typeof AdminPermissions {
		return AdminPermissions;
	}

	public logout(): void {
		this.userService.logout();
	}

	public goToOrganizationList(): void {
		this.organizationService.forceOrganizationSelection = true;
		localStorage.removeItem('organizationId');
		this.router.navigate(['/organizations']);
	}

}
