<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Como podemos ajudar?</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding-top: 0px;">
	<form [formGroup]="messageForm">
		<div class="form-group">
			<label>Assunto</label>
			<input class="form-control" placeholder="" formControlName="subject">
		</div>

		<div class="form-group">
			<label>Mensagem</label>
			<textarea rows="4" class="form-control" placeholder="Informe sua dúvida ou sugestão..." formControlName="message"></textarea>
		</div>
	</form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Fechar</button>
    <button type="button" class="btn btn-primary" (click)="postHelpMessage()" [disabled]="isLoading || messageForm.invalid">Enviar</button>
</div>
