<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" >
    <div [innerHTML]="text"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)" *ngIf="showCancelButton">Fechar</button>
    <button type="button" class="btn btn-primary" (click)="modal.close(true)" *ngIf="showConfirmButton">{{confirmText}}</button>
</div>
