import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { EmptyPage, Organization, PageResponse, Role, User } from '@app/model';
import { OrganizationService, RoleService, UserService } from '@app/service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';

interface SimpleFilter {
	name: string,
	email: string
}

@Component({
	selector: 'app-user-search',
	templateUrl: './user-search.component.html',
	styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {

	public users: PageResponse<User> = new EmptyPage();
	public selectedUsers: User[] = [];

	public selectedUser: User;

	public searchFilter: string;
	public simpleFilter: SimpleFilter = {
		name: '',
		email: ''
	};
	public orderBy: string | null = null;
	public direction: string | null = null;

	public roleIdNotIn: string;
	@Output() public action = new EventEmitter<any>();

	public isLoading = false;

	constructor(public userService: UserService,
				public organizationService: OrganizationService,
				public roleService: RoleService,
	            public modal: NgbActiveModal,
	            private formBuilder: FormBuilder) {
	}

	ngOnInit(): void {
		this.filter(0);
	}

	public addRemoveUser(user: any): void {
		const index = this.selectedUsers.indexOf(user);
		if (index > -1) {
			this.selectedUsers.splice(index, 1);
		} else {
			this.selectedUsers = [ ...this.selectedUsers, user ];
		}
	}

	public filter(offset: number) {
		if(this.searchFilter && this.simpleFilter) {
			this.simpleFilter.name = this.searchFilter;
			this.simpleFilter.email = this.searchFilter;
		}

		this.userService.filter(_.assign({
			...this.simpleFilter,
			linesPerPage: 20,
			page: offset,
			orderBy: this.orderBy,
			direction: this.direction,
			roleIdNotIn: this.roleIdNotIn ? this.roleIdNotIn : null,
			status: 'ACTIVE'
		})).subscribe((users: PageResponse<User>) => {
			this.users = users;
		})
	}

	public emitAction() {
		this.isLoading = true;
		this.action.emit(this.selectedUser);
		// this.action.emit(this.selectedUsers);
	}

}
