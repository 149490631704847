<div class="app-header" *ngIf="userService.currentUser">

	<div class="logo-wrapper">

		<div class="header-dropdown" ngbDropdown container="body" (openChange)="showSwitch = !showSwitch">
			<button class="btn" ngbDropdownToggle>
				<i class="fas fa-th"></i>
			</button>

			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
				<evo-switch *ngIf="switchProductsOrganizations && showSwitch"
				            (switchOrganizations)="goToOrganizationList()"
				            [switchProductsOrganizations]="switchProductsOrganizations"
				            [showAdmin]="false">
				</evo-switch>
			</div>
		</div>


		<div class="product-logo ADMIN"></div>

		<h5>Administrador</h5>
	</div>

	<div class="main-menu" *ngIf="organizationService.currentOrganization.id && organizationService.currentOrganization.name">
		<span>{{ organizationService.currentOrganization.name }}</span>

		<button routerLink="/organizations/{{organizationService.currentOrganization.id}}" routerLinkActive="active" *ngxPermissionsOnly="adminPermissionsArr">
			Detalhes
		</button>
		<button routerLink="/users" routerLinkActive="active"
		        *ngxPermissionsOnly="[adminPermissions.VIEW_USERS, adminPermissions.INVITE_USER_ORGANIZATION, adminPermissions.REMOVE_USER_ORGANIZATION, adminPermissions.MANAGE_USERS_INVITATION, adminPermissions.ALL_PERMISSIONS]">Usuários
		</button>
		<button routerLink="/roles" routerLinkActive="active"
		        *ngxPermissionsOnly="[adminPermissions.VIEW_ROLES, adminPermissions.EDIT_ROLES, adminPermissions.CREATE_ROLES, adminPermissions.EDIT_USERS_ROLES, adminPermissions.ALL_PERMISSIONS]">Perfis
		</button>
	</div>

	<div class="action-wrapper">
		<div class="action-buttons">
			<!-- <button class="btn">
				<i class="fas fa-bell"></i>
			</button>
			<button class="btn">
				<i class="fas fa-question-circle"></i>
			</button> -->
			<button class="btn" (click)="openHelpDialog()" *ngIf="organizationService.currentOrganization?.id && showSupport">
				<img src="/assets/icons/helpdesk.svg" width="18" height="18" style="opacity: 0.7;"/>
			</button>

			<a href="https://evollo.com.br/artigos" target="_blank" class="btn" *ngIf="showFaq">
				<i class="far fa-question-circle"></i>
			</a>

			<!-- <button class="btn" (click)="goToOrganizationList()">
				<i class="fas fa-cog"></i>
			</button> -->
		</div>

		<span class="user-info">
			{{ userService.currentUser.name }}
		</span>

		<div class="header-dropdown" ngbDropdown container="body">
			<avatar-initials style="cursor: pointer;" [small]="true" [initialsColor]="'#fff'" [name]="userService.currentUser.name" ngbDropdownToggle></avatar-initials>

			<!-- <button class="btn pl-2" data-no-icon="true" ngbDropdownToggle>
				<i class="fas fa-ellipsis-h"></i>
			</button> -->
			<div ngbDropdownMenu class="dropdown-menu dropdown-menu-right py-2" role="menu">
				<a class="dropdown-item pointer" [href]="idUrl">
					<i class="far fa-user"></i>
					<span class="">Configuração da conta</span>
				</a>

				<hr class="my-1"/>

				<a class="dropdown-item pointer" (click)="logout()">
					<img class="mr-2" src="/assets/icons/sign-out.svg" width="18" height="18" style="opacity: 0.8"/>
					Sair
				</a>
			</div>
		</div>
	</div>

</div>
